.formWrapper {
    width: 80%;
    margin: auto;
    padding: 1em 0;
}



.searchWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
}

.cardHeader {
    display: flex;
    align-items: center;
    gap: 1.2em;
}

@media (min-width: 280px) and (max-width: 1024px) {
    .formWrapper {
        width: 100%;
        padding: 2em 0;
    }

    .selectWrapper {
        width: 100%;
    }

    .searchWrapper {
        width: 100%;
    }

    .cardHeader {
        display: flex;
        align-items: center;
        gap: 1.2em;
        flex-wrap: wrap;
    }
}