.downloadCsvTemplate {
    cursor: pointer !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}
.uploadCsv {
    width: 346px;
    height: 246px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10em auto 14em;
}
.uploadCsvButton {
    border: 3px dotted var(--green);
    position: absolute;
    background: var(--white);
    padding: 8em;
    font-weight: bold;
    display: flex;
    gap: 0.5em;
}
.inputTag {
    border: 1px solid;
    width: 100%;
    height: 100%;
    opacity: 0;
}